import React from "react"

import { NavTabs, TabPane } from "../ak-tabs/tabs"

const AcfOrganziation = data => {
  const acfBlocks = data.data.acf.cards

  const tabs = acfBlocks.reduce((result, currentValue) => {
    result.push({
      name: currentValue.title,
      target: currentValue.title.split(" ").join(""),
    })

    return result
  }, [])

  return (
    <>
      <NavTabs data={tabs} className="organization" />
      {acfBlocks.map((item, i) => {
        return (
          <TabPane
            key={item.title}
            id={item.title.split(" ").join("")}
            className={i === 0 ? "active" : ""}
          >
            <img
              alt={item.title}
              src={item.image.mediaItemUrl}
              className="mw-100"
            />
          </TabPane>
        )
      })}
    </>
  )
}

export default AcfOrganziation
