import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

import SEO from "../../components/seo"
import Layout from "../../components/layout"

import "../../graphql/fragments"

import { Breadcrumbs } from "../../components/breadcrumbs/breadcrumbs"
import { CoverMain } from "../../components/cover-main/cover-main"
// import { AcfListImage } from "../../components/list-image"
import { AcfColHeadline } from "../../components/columned-headline"
import { Container, Section } from "../../components/grid/grid"
// import AcfOrganization from "../../components/acf-organization"
import AcfOrganziation from "../../components/acf-organization"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

/* ==========================================================================
    Render
   ========================================================================== */

const StructureOrganizationDUTI = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      imgCoverMain: file(relativePath: { eq: "imgBannerSinarmas.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 738) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      wordPress {
        page(id: "structure-organization-duti", idType: URI) {
          title
          blocks {
            name
            ...CoreCoverBlock
            ...AcfColHeadlineBlock
            ...CoreShortcodeBlock
            ...CoreImageBlock
            ...AcfOrganizationBlock
          }
          translation(language: ID) {
            title
            blocks {
              name
              ...CoreCoverBlock
              ...AcfColHeadlineBlock
              ...CoreShortcodeBlock
              ...CoreImageBlock
              ...AcfOrganizationBlock
            }
            seo {
              title
              metaDesc
              twitterTitle
              twitterDescription
              opengraphTitle
              opengraphDescription
            }
          }
          seo {
            title
            twitterDescription
            metaDesc
            opengraphDescription
          }
        }
      }
    }
  `)

  const wordPress = data?.wordPress?.page?.blocks
  const seo = data.wordPress.page.seo
  const cover =
    wordPress &&
    wordPress.find(x => x.name === "core/cover") === wordPress[0] &&
    wordPress[0]

  return (
    <Layout location={location} currentLocation={location.uri}>
      <SEO
        title={seo?.title}
        fbTitle={seo?.opengraphTitle}
        twitterTitle={seo?.twitterTitle}
        twitterDescription={seo?.twitterDescription}
        fbDescription={seo?.opengraphDescription}
        description={seo?.opengraphDescription}
        url={location?.href}
        fbImg={
          cover
            ? cover.attributes.url
            : data.imgVideoCover.childImageSharp.fluid
        }
        twitterImg={
          cover
            ? cover.attributes.url
            : data.imgVideoCover.childImageSharp.fluid
        }
        img={
          cover
            ? cover.attributes.url
            : data.imgVideoCover.childImageSharp.fluid
        }
      />
      <Helmet>
        <body className="bd-page" />
      </Helmet>

      {wordPress && (
        <CoverMain
          h1={data?.wordPress?.page?.title}
          img={
            cover
              ? cover.attributes.url
              : data.imgCoverMain.childImageSharp.fluid
          }
          alt="Board of directors"
          className="cover-md"
        >
          <Breadcrumbs path={location.uri} className="breadcrumb-light" />
        </CoverMain>
      )}

      {wordPress?.map((item, i) => {
        switch (item.name) {
          case "acf/col-headline":
            return <AcfColHeadline data={item} key={`col-headline-${i}`} />
          case "core/shortcode":
            if (item.attributes.text === "structure-organization-tabs") {
              return (
                <Container>
                  <div className={`nav-tabs-wrapper d-flex`}>
                    <ul class={`nav nav-tabs`} role="tablist">
                      <li className={`nav-item`}>
                        <Link
                          className={`nav-link`}
                          role="tab"
                          to="/about-us/structure-organization"
                        >
                          Sinar Mas Land
                        </Link>
                      </li>
                      <li className={`nav-item`}>
                        <Link
                          className={`nav-link`}
                          role="tab"
                          to="/about-us/structure-organization-bsd"
                        >
                          BSDE
                        </Link>
                      </li>
                      <li className={`nav-item`}>
                        <div className={`nav-link active`} role="tab">
                          DUTI
                        </div>
                      </li>
                    </ul>
                  </div>
                </Container>
              )
            }
          case "core/image":
            return (
              <Section>
                <Container>
                  <img
                    alt="structure organization"
                    src={item.attributes.url}
                    className="w-100"
                  />
                </Container>
              </Section>
            )
          case "acf/organization":
            return (
              <Container>
                <AcfOrganziation data={item} />
              </Container>
            )
          default:
            return <div key={`empty-block-${i}`}></div>
        }
      })}
    </Layout>
  )
}

export default StructureOrganizationDUTI
